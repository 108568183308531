import { Component, Inject, LOCALE_ID, Renderer2, OnInit } from "@angular/core";
import { ConfigService } from "../@vex/services/config.service";
import { Settings } from "luxon";
import { DOCUMENT } from "@angular/common";
import { Platform } from "@angular/cdk/platform";
import { NavigationService } from "../@vex/services/navigation.service";

import { iconsFA } from "../static-data/icons-fa";
import { iconsIC } from "../static-data/icons-ic";

import { LayoutService } from "../@vex/services/layout.service";
import { ActivatedRoute } from "@angular/router";
import { filter } from "rxjs/operators";
import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { SplashScreenService } from "../@vex/services/splash-screen.service";
import { Style, StyleService } from "../@vex/services/style.service";
import { ConfigName } from "../@vex/interfaces/config-name.model";
import { AuthenticationService } from "./core/authentication/authentication.service";
import { ChatAppSignalRService } from "./core/signalr/chat-app-signal-r.service";
// import { ToastrService } from 'ngx-toastr';
import { MatSnackBar } from "@angular/material";
import { ChatMessage } from "./pages/apps/chat/chat.component";

@Component({
  selector: "vex-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "vex";

  constructor(
    private configService: ConfigService,
    private styleService: StyleService,
    private renderer: Renderer2,
    private platform: Platform,
    private authService: AuthenticationService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(LOCALE_ID) private localeId: string,
    private layoutService: LayoutService,
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    private splashScreenService: SplashScreenService,
    // private toastr: ToastrService,
    private _snackBar: MatSnackBar,
    private chatSignalR: ChatAppSignalRService
  ) {
    Settings.defaultLocale = this.localeId;

    authService.findPermisos();
    authService.setDecodeToken();

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, "is-blink");
    }

    /**
     * Customize the template to your needs with the ConfigService
     * Example:
     *  this.configService.updateConfig({
     *    sidenav: {
     *      title: 'Custom App',
     *      imageUrl: '//placehold.it/100x100',
     *      showCollapsePin: false
     *    },
     *    showConfigButton: false,
     *    footer: {
     *      visible: false
     *    }
     *  });
     */

    /**
     * Config Related Subscriptions
     * You can remove this if you don't need the functionality of being able to enable specific configs with queryParams
     * Example: example.com/?layout=apollo&style=default
     */
    this.route.queryParamMap
      .pipe(
        filter(
          (queryParamMap) =>
            queryParamMap.has("rtl") &&
            coerceBooleanProperty(queryParamMap.get("rtl"))
        )
      )
      .subscribe((queryParamMap) => {
        this.document.body.dir = "rtl";
        this.configService.updateConfig({
          rtl: true,
        });
      });

    this.route.queryParamMap
      .pipe(filter((queryParamMap) => queryParamMap.has("layout")))
      .subscribe((queryParamMap) =>
        this.configService.setConfig(queryParamMap.get("layout") as ConfigName)
      );

    this.route.queryParamMap
      .pipe(filter((queryParamMap) => queryParamMap.has("style")))
      .subscribe((queryParamMap) =>
        this.styleService.setStyle(queryParamMap.get("style") as Style)
      );

    this.navigationService.items = [
      {
        type: "link",
        label: "Dashboard",
        route: "/dashboards/analitico",
        permisos: ["Dashboard"],
        icon: iconsIC.layers,
      },
      {
        type: "dropdown",
        label: "Presupuesto",
        icon: iconsFA.calculator,
        permisos: [
          "Presupuesto",
          "Planificacion_de_compras",
          "Captura",
          "Gastos_Basicos",
        ],
        children: [
          {
            type: "link",
            label: "Planificación de Compras",
            route: "/modulos/pl-compras",
            permisos: ["Planificacion_de_compras"],
          },
          {
            type: "link",
            label: "Monitoreo Plan de Compras",
            route: "/modulos/monitoreo-pl-compras",
            permisos: ["Monitoreo_Plan_Compras"],
          },
          {
            type: "link",
            label: "Captura",
            route: "/modulos/captura",
            permisos: ["Captura"],
          },
          // {
          //   type: "link",
          //   label: "Cálculos de Comisiones",
          //   route: "/modulos/calculos-comision",
          //   permisos: ["calculos_comisiones"],
          // },
          {
            type: "link",
            label: "Revisión",
            route: "/modulos/captura/revision",
            permisos: ["Revision"],
          },
          {
            type: "link",
            label: "Gastos Básicos",
            route: "/modulos/gastos-basicos",
            permisos: ["Gastos_Basicos"],
          },
          {
            type: "link",
            label: "Copia Año Anterior",
            route: "/modulos/captura/copia-anio",
            permisos: ["copia_anio"],
          },
          {
            type: "link",
            label: "Sincronizar",
            route: "/modulos/captura/sincronizar",
            permisos: ["sincronizar"],
          },
          {
            type: "link",
            label: "Cargar Captura Activos",
            route: "/modulos/captura/cargar-activo",
            permisos: ["captura_cargar_activo_presupuesto"],
          },
          {
            type: "link",
            label: "Captura Masiva ",
            route: "/modulos/captura/masiva",
            permisos: ["captura_masiva_presupuesto"],
          },
          {
            type: "link",
            label: "Versión Presupuesto",
            route: "/modulos/version-presupuesto",
            permisos: ["version_presupuesto"],
          },
          {
            type: "link",
            label: "Cálculo General",
            route: "/modulos/calculo-general",
            permisos: ["calculo_general"],
          },
          {
            type: "dropdown",
            label: "Reportes",
            icon: iconsFA["chart-bar"],
            permisos: ["Reportes"],
            children: [
              {
                type: "dropdown",
                label: "Resumen",
                permisos: ["Resumen"],
                children: [
                  {
                    type: "link",
                    permisos: ["Viabudget"],
                    label: "Resumen General",
                    route:
                      "/modulos/reportes/presupuesto/resumen/resumen-general",
                  },
                  {
                    type: "link",
                    permisos: ["Viabudget"],
                    label: "ViaBudget",
                    route:
                      "/modulos/reportes/presupuesto/resumen/resumen-viabudget",
                  },
                  {
                    type: "link",
                    permisos: ["Viabudget_Ampliado"],
                    label: "ViaBudget Ampliado",
                    route:
                      "/modulos/reportes/presupuesto/resumen/resumen-viabudget-ampliado",
                  },
                  // {
                  //   type: "link",
                  //   permisos: ["Resumen_Cuentas"],
                  //   label: "Resumen Cuentas",
                  //   route:
                  //     "/modulos/reportes/presupuesto/resumen/resumen-cuentas",
                  // },
                  /*{
                    type: "link",
                    permisos: ["Resumen_Linea"],
                    label: "Resumen Linea",
                    route:
                      "/modulos/reportes/presupuesto/resumen/resumen-linea",
                  },*/
                  // {
                  //   type: "link",
                  //   permisos: ["Resumen_Departamentos"],
                  //   label: "Resumen Departamentos",
                  //   route:
                  //     "/modulos/reportes/presupuesto/resumen/resumen-departamentos",
                  // },
                  // {
                  //   type: "link",
                  //   permisos: ["Resumen_Plan_Compras"],
                  //   label: "Resumen Plan Compras",
                  //   route:
                  //     "/modulos/reportes/presupuesto/resumen/resumen-plan-compras",
                  // },
                  {
                    type: "link",
                    permisos: ["Resumen_Producto"],
                    label: "Resumen Productos",
                    route:
                      "/modulos/reportes/presupuesto/resumen/resumen-productos",
                  },
                  {
                    type: "link",
                    permisos: ["Resumen_Producto"],
                    label: "Resumen Vehículos",
                    route:
                      "/modulos/reportes/presupuesto/resumen/resumen-vehiculo",
                  },
                  {
                    type: "link",
                    permisos: ["Resumen_Producto"],
                    label: "Resumen Taller",
                    route:
                      "/modulos/reportes/presupuesto/resumen/resumen-taller",
                  },
                  {
                    type: "link",
                    permisos: ["Resumen_Producto"],
                    label: "Resumen Repuestos",
                    route:
                      "/modulos/reportes/presupuesto/resumen/resumen-repuesto",
                  },
                  
                  // {
                  //   type: "link",
                  //   permisos: ["Resumen_Producto"],
                  //   label: "Presupuesto Productos",
                  //   route:
                  //     "/modulos/reportes/presupuesto/resumen/presupuesto-productos",
                  // },
                  // {
                  //   type: "link",
                  //   permisos: ["Resumen_Marca_Margen"],
                  //   label: "Resumen Marca Margen",
                  //   route:
                  //     "/modulos/reportes/presupuesto/resumen/resumen-marcas-margen",
                  // },
                  // {
                  //   type: "link",
                  //   permisos: ["Resumen_Marcas"],
                  //   label: "Resumen Marcas",
                  //   route:
                  //     "/modulos/reportes/presupuesto/resumen/resumen-marcas",
                  // },
                  {
                    type: "link",
                    permisos: ["Resumen_Estado_Captura"],
                    label: "Resumen Estado Captura",
                    route:
                      "/modulos/reportes/presupuesto/resumen/resumen-estado-captura",
                  },
                  {
                    type: "link",
                    permisos: ["Resumen_Estado_Resultado"],
                    label: "Resumen Estado Resultado",
                    route:
                      "/modulos/reportes/presupuesto/resumen/resumen-estado-resultado",
                  },
                  {
                    type: 'link',
                    permisos: ["Resumen_Estado_Situacion"],
                    label: 'Resumen Estado Situación',
                    route: '/modulos/reportes/presupuesto/resumen/resumen-estado-situacion',
                  },
                  {
                    type: "link",
                    permisos: ["Resumen_Flujo_Efectivo"],
                    label: "Resumen Flujo Efectivo",
                    route:
                      "/modulos/reportes/presupuesto/resumen/resumen-flujo-efectivo",
                  },

                  {
                    type: "link",
                    permisos: ["Resumen_Balance_Common_Size"],
                    label: "Common Size Porcentual",
                    route:
                      "/modulos/reportes/presupuesto/resumen/balance-common-size",
                  },
                  {
                    type: "link",
                    permisos: ["Resumen_Balance_General"],
                    label: "Common Size Valores",
                    route:
                      "/modulos/reportes/presupuesto/resumen/resumen-balance-general",
                  },
                  {
                    type: "link",
                    permisos: ["Resumen_Forecast"],
                    label: "ROLLING FORECAST",
                    route:
                      "/modulos/reportes/presupuesto/resumen/forecast",
                  },
                  {
                    type: "link",
                    permisos: ["Resumen_Forecast"],
                    label: "Indicadores",
                    route:
                      "/modulos/reportes/presupuesto/resumen/resumen-indicadores-presupuesto",
                  },


                ],
              },
              {
                type: "dropdown",
                label: "Gráficos",
                permisos: ["graficos"],
                children: [
                  // {
                  //     type: 'link',
                  //     permisos: ["Viabudget"],
                  //     label: 'Costos Consolidados',
                  //     route: '/modulos/reportes/presupuesto/grafico/grafico-costos-consolidados',
                  // },
                  {
                    type: "link",
                    permisos: ["grafico_ingresos"],
                    label: "Ingresos",
                    route:
                      "/modulos/reportes/presupuesto/grafico/grafico-ingreso",
                  },
                  {
                    type: "link",
                    permisos: ["grafico_gastos"],
                    label: "Gastos",
                    route:
                      "/modulos/reportes/presupuesto/grafico/grafico-gastos",
                  },
                  {
                    type: "link",
                    permisos: ["Grafico_Costos"],
                    label: "Costos",
                    route:
                      "/modulos/reportes/presupuesto/grafico/grafico-costos",

                  },
                  {
                    type: "link",
                    permisos: ["grafico_ingresos_ejec_vs_pre"],
                    label: "Ingresos Ejec vs Pre",
                    route:
                      "/modulos/reportes/presupuesto/grafico/grafico-ingreso-ejecutado-vs-presupuesto",

                  },

                  {
                    type: "link",
                    permisos: ["grafico_costo_ejec_vs_pre"],
                    label: "Costos Ejec vs Pre",
                    route:
                      "/modulos/reportes/presupuesto/grafico/grafico-costo-ejecutado-vs-presupuesto",

                  },

                  {
                    type: "link",
                    permisos: ["grafico_gasto_ejec_vs_pre"],
                    label: "Gastos Ejec vs Pre",
                    route:
                      "/modulos/reportes/presupuesto/grafico/grafico-gasto-ejecutado-vs-presupuesto",

                  },


                  // {
                  //   type: "link",
                  //   permisos: ["grafico_dashboard"],
                  //   label: "Dashboard",
                  //   route:
                  //     "/modulos/reportes/presupuesto/grafico/grafico-dashboard",
                  // },
                  // {
                  //   type: "link",
                  //   permisos: ["grafico_departamentos"],
                  //   label: "Gráfico Departamentos",
                  //   route:
                  //     "/modulos/reportes/presupuesto/grafico/grafico-departamentos",
                  // },
                  // {
                  //   type: "link",
                  //   permisos: ["grafico_ejecutado_vs_presupuesto"],
                  //   label: "Ejecutado VS Presupuesto",
                  //   route:
                  //     "/modulos/reportes/presupuesto/grafico/grafico-ejecutado-vs-presupuesto",
                  // },
                  // {
                  //   type: "link",
                  //   permisos: ["grafico_ejecutado_vs_presupuesto"],
                  //   label: "Ejec VS Pres Mensual",
                  //   route:
                  //     "/modulos/reportes/presupuesto/grafico/grafico-ejecutado-vs-presupuesto-mensual",
                  // },
                ],
              },
            ],
          },
        ],
      },
      {
        type: "dropdown",
        label: "Flujo de Vehículo",
        icon: iconsFA.car,
        permisos: [
          "Flujo_Vehiculo"
        ],
        children: [
          {
            type: "link",
            label: "Solicitud",
            route: "/modulos/flujo-vehiculo/solicitud-flujo-vehiculo",
            permisos: ["Solicitud_Flujo_Vehiculo"]
          },
          {
            type: "link",
            label: "Revisión",
            route: "/modulos/flujo-vehiculo/revision-flujo-vehiculo",
            permisos: ["Autorizar_Flujo_Vehiculo"]
          }
        ]
      },
      {
        type: "dropdown",
        permisos: ["Activos_Fijos"],
        children: [
          {
            type: "link",
            label: "Proyección Presupuesto",
            route: "/modulos/activos-fijo",
            permisos: ["Activos_Fijos"],
          },
          {
            type: "link",
            permisos: ["ArticulosActivosFijos"],
            label: "Artículos Activos Fijos",
            route: "/modulos/mantenimientos/activosfijos",
          },
          {
            type: "link",
            permisos: ["CategoriaActivoFijo"],
            label: "Categoria Activo Fijo",
            route: "/modulos/mantenimientos/categoria-activo-fijo",
          },
          {
            type: "link",
            permisos: ["CuentaEnrrolActivo"],
            label: "Cuenta Enrrol Activo",
            route: "/modulos/mantenimientos/cuenta-enrroll-activo",
          },
          // {
          //   type: "link",
          //   label: "Asignación de activos",
          //   route: "/modulos/activos-fijo",
          //   permisos: ["Activos_Fijos"],
          // },
          // {
          //   type: "link",
          //   label: "Movimiento de activos",
          //   route: "/modulos/activos-fijo",
          //   permisos: ["Activos_Fijos"],
          // },
          // {
          //   type: "link",
          //   label: "Cálculo depreciación",
          //   route: "/modulos/activos-fijo",
          //   permisos: ["Activos_Fijos"],
          // },
          // {
          //   type: "link",
          //   label: "Baja de activos",
          //   route: "/modulos/activos-fijo",
          //   permisos: ["Activos_Fijos"],
          // },
        ],
        label: "Activos Fijo",
        icon: iconsFA["comment-dollar"],
      },
      {
        type: "dropdown",
        label: "Mantenimientos",
        icon: iconsFA["clipboard-list"],
        permisos: ["Mantenimiento"],
        children: [

          {
            type: "link",
            permisos: ["Articulo"],
            label: "Articulo",
            route: "/modulos/mantenimientos/articulo",
          },
          {
            type: "link",
            permisos: ["Articulo_Negocio_Enroll"],
            label: "Negocios / Articulos Enroll",
            route: "/modulos/mantenimientos/articulo-negocio-enrroll",
          },
          {
            type: "link",
            permisos: ["Marca_Gerente_Enroll"],
            label: "Marca / Gerente Enroll",
            route: "/modulos/mantenimientos/marca-gerente-enrroll",
          },
          {
            type: "link",
            permisos: ["ClasificacionCuenta_TipoArticulo_Enroll"],
            label: "Clasificacion Cuenta / Tipo Articulo Enroll",
            route: "/modulos/mantenimientos/clasificacioncuenta-tipoarticulo-enrroll",
          },
          {
            type: "link",
            permisos: ["Anio"],
            label: "Edición Año",
            route: "/modulos/mantenimientos/anio",
          },
          {
            type: "link",
            permisos: ["Area"],
            label: "Edición Area Departamental",
            route: "/modulos/mantenimientos/areas",
          },

          // {
          //   type: "link",
          //   permisos: ["Categoria"],
          //   label: "Categoria",
          //   route: "/modulos/mantenimientos/categorias",
          // },
          {
            type: "link",
            permisos: ["Empleados"],
            label: "Maestra de Empleados",
            route: "/modulos/mantenimientos/empleados",
          },

          {
            type: "link",
            permisos: ["EmpleadosPresupuesto"],
            label: "Edición Empleados Ppto",
            route: "/modulos/mantenimientos/empleados-presupuesto",
          },
          {
            type: "link",
            permisos: ["Concepto_Presupuesto_Categoria"],
            label: "Concepto Ppto Categoria",
            route: "/modulos/mantenimientos/concepto-presupuesto-categoria",
          },



          // {
          //   type: "link",
          //   permisos: ["Negocio_Presupuesto_Canal"],
          //   label: "Negocio Presupuesto Canal",
          //   route: "/modulos/mantenimientos/negocio-presupuesto-canal-enrroll",
          // },
          // {
          //   type: "link",
          //   permisos: ["Negocio_Presupuesto_Marca"],
          //   label: "Negocio Presupuesto Marca",
          //   route: "/modulos/mantenimientos/negocio-presupuesto-marca-enrroll",
          // },

          // NEGOCIO
          {
            type: "link",
            permisos: ["Negocio"],
            label: "Edición Negocio",
            route: "/modulos/mantenimientos/negocios",
          },
          {
            type: "link",
            permisos: ["Negocio_Presupuesto_Cuenta"],
            label: "Negocios / Cuentas Enroll",
            route: "/modulos/mantenimientos/negocio-presupuesto-cuenta-enrroll",
          },

          // MARCA
          {
            type: "link",
            permisos: ["Marcas"],
            label: "Edición Marca",
            route: "/modulos/mantenimientos/marcas",
          },
          {
            type: "link",
            permisos: ["MarcasProcedencia"],
            label: "Edición Marca Procedencia",
            route: "/modulos/mantenimientos/marcas-procedencia",
          },
          {
            type: "link",
            permisos: ["Marca_Aplica_Pago_Rundownd"],
            label: "Marca Aplica Pago Rundownd",
            route: "/modulos/mantenimientos/marca-aplica-pago-rundownd",
          },
          {
            type: "link",
            permisos: ["Modelo"],
            label: "Modelo",
            route: "/modulos/mantenimientos/modelo",
          },

          // {
          //   type: "link",
          //   permisos: ["ArticulosActivosFijos"],
          //   label: "Artículos Activos Fijos",
          //   route: "/modulos/mantenimientos/activosfijos",
          // },

          {
            type: "link",
            permisos: ["Sucursales"],
            label: "Edición Sucursal ",
            route: "/modulos/mantenimientos/sucursales",
          },
          {
            type: "link",
            permisos: ["TipoEmpleado"],
            label: "Edición Tipo de Empleados",
            route: "/modulos/mantenimientos/tipoempleados",
          },
          {
            type: "link",
            permisos: ["Jornada"],
            label: "Edición Jornadas Empleados",
            route: "/modulos/mantenimientos/jornadas",
          },
          {
            type: "link",
            permisos: ["Canales"],
            label: "Canal",
            route: "/modulos/mantenimientos/canales",
          },
          {
            type: "link",
            permisos: ["Precios_Taller"],
            label: "Productos Precio Taller ",
            route: "/modulos/mantenimientos/precios-taller",
          },
          {
            type: "link",
            permisos: ["Precios_Respuesto"],
            label: "Productos Precio Repuestos",
            route: "/modulos/mantenimientos/precios-respuesto",
          },
          // {
          //   type: "link",
          //   permisos: ["Conceptos_Presupuesto"],
          //   label: "Conceptos",
          //   route: "/modulos/mantenimientos/concepto-p",
          // },

          {
            type: "link",
            permisos: ["Productos_Precios"],
            label: "Productos Precio",
            route: "/modulos/mantenimientos/productos-precio",
          },
          {
            type: "link",
            permisos: ["Productos_Costos"],
            label: "Productos Costos",
            route: "/modulos/mantenimientos/productos-costo",
          },
          {
            type: "link",
            permisos: ["Productos_Descuento"],
            label: "Productos Descuento",
            route: "/modulos/mantenimientos/productos-descuentos",
          },
          {
            type: "link",
            permisos: ["Tasas_Monedas"],
            label: "Tasas Monedas",
            route: "/modulos/mantenimientos/productos-descuentos",
          },
          {
            type: "link",
            permisos: ["Monedas"],
            label: "Tipo de Moneda",
            route: "/modulos/mantenimientos/monedas",
          },
          {
            type: "link",
            permisos: ["TipoCombustible"],
            label: "Tipo Combustible",
            route: "/modulos/mantenimientos/tipo-combustibles",
          },
          {
            type: "link",
            permisos: ["Combustible"],
            label: "Edición de Combustibles",
            route: "/modulos/mantenimientos/combustibles",
          },

          {
            type: "link",
            permisos: ["Cuenta_Contable"],
            label: "Cuenta Contable",
            route: "/modulos/mantenimientos/cuentas-contables",
          },
          {
            type: "link",
            permisos: ["Cuenta_Contable_CalculoEnroll"],
            label: "Cuenta contable / Calculo Enroll",
            route: "/modulos/mantenimientos/cuentas-contable-calculoEnrrol",
          },

          {
            type: "link",
            permisos: ["CategoriaCuenta"],
            label: "Categoria Cuenta",
            route: "/modulos/mantenimientos/categoria-cuenta",
          },
          // {
          //   type: "link",
          //   permisos: ["TipoPagoComision"],
          //   label: "Tipo Pago Comision",
          //   route: "/modulos/mantenimientos/tipo-pago-comision",
          // },
          // {
          //   type: "link",
          //   permisos: ["TipoIndicadoresComision"],
          //   label: "Tipo Indicadores Comision",
          //   route: "/modulos/mantenimientos/tipo-indicadores-comision",
          // },
          {
            type: "link",
            permisos: ["IndicadoresComision"],
            label: "Indicadores Comision",
            route: "/modulos/mantenimientos/indicadores-comision",
          },
          {
            type: "link",
            permisos: ["Puesto"],
            label: "Edición Puestos de Empleados",
            route: "/modulos/mantenimientos/puesto",
          },
          // {
          //   type: "link",
          //   permisos: ["Puesto_Comision"],
          //   label: "Puesto Comision",
          //   route: "/modulos/mantenimientos/puesto-comision",
          // },


          //DEPARTAMENTO
          {
            type: "link",
            permisos: ["Departamento"],
            label: "Departamento",
            route: "/modulos/mantenimientos/departamento",
          },

          {
            type: "link",
            permisos: ["Departamento_Presupuesto_NegocioEnroll"],
            label: "Negocios/Departamentos Enroll",
            route:
              "/modulos/mantenimientos/departamento-presupuesto-negocio-enrroll",
          },
          {
            type: "link",
            permisos: ["Conceptos_Mantenimiento"],
            label: "Conceptos Departamento",
            route: "/modulos/mantenimientos/conceptos",
          },



          // {
          //   type: "link",
          //   permisos: ["Usuario_Canal_Enroll"],
          //   label: "Usuario Canal Enroll",
          //   route: "/modulos/mantenimientos/usuario-canal-enroll",
          // },
          // {
          //   type: "link",
          //   permisos: ["Usuario_Marca_Enroll"],
          //   label: "Usuario Marca Enroll",
          //   route: "/modulos/mantenimientos/usuario-marca-enroll",
          // },


          {
            type: "link",
            permisos: ["EjecutadoStock"],
            label: "Ejecutado Stock",
            route: "/modulos/mantenimientos/ejecutado-stock",
          },
          {
            type: "link",
            permisos: ["TasaMoneda"],
            label: "Tasa Moneda",
            route: "/modulos/mantenimientos/tasamoneda",
          },
          {
            type: "link",
            permisos: ["LineaEstado"],
            label: "Linea Estado",
            route: "/modulos/mantenimientos/lineaestado",
          },
          {
            type: "link",
            permisos: ["Config-Parametros"],
            label: "Parametros",
            route: "/modulos/configuraciones/parametros",
          },
          {
            type: "link",
            permisos: ["Config-ParametrosConstante"],
            label: "Parámetros de Cálculos",
            route: "/modulos/configuraciones/parametrosConstante",
          },
        ],
      },

      {
        type: "dropdown",
        label: "Crm",
        permisos: [],
        icon: iconsIC.trending_up,
        children: [],
      },

      {
        type: "dropdown",
        label: "Compras",
        permisos: [],
        icon: iconsFA["users-cog"],
        children: [],
      },

      {
        type: "dropdown",
        label: "Inventario",
        permisos: [],
        icon: iconsFA["list"],
        children: [],
      },

      {
        type: "dropdown",
        label: "Ventas",
        permisos: [],
        icon: iconsFA["cash-register"],
        children: [],
      },
      {
        type: "dropdown",
        label: "Finanzas",
        permisos: [],
        icon: iconsFA["calculator"],
        children: [],
      },
      {
        type: "dropdown",
        label: "Recursos Humanos",
        permisos: [],
        icon: iconsFA["users-cog"],
        children: [],
      },
      {
        type: "dropdown",
        label: "Proyectos",
        permisos: [],
        icon: iconsFA["project-diagram"],
        children: [],
      },
      // {
      //   type: 'subheading',
      //   label: 'Customize',
      //   children: []
      // },

      {
        type: "dropdown",
        permisos: ["Configuraciones"],
        children: [

          {
            type: "link",
            permisos: ["Config-Usuarios"],
            label: "Usuarios",
            route: "/modulos/configuraciones/usuarios",
          },
          {
            type: "link",
            permisos: ["Rol"],
            label: "Edición Roles",
            route: "/modulos/mantenimientos/rol",
          },
          {
            type: "link",
            permisos: ["RolPermisoEnroll"],
            label: "Edición Roles Permisos",
            route: "/modulos/mantenimientos/rol-permiso-enroll",
          },
          {
            type: "link",
            permisos: ["Usuario_Permisos_Enroll"],
            label: "Usuario Permiso Enroll",
            route: "/modulos/mantenimientos/usuario-permisos-enroll",
          },
          {
            type: "link",
            permisos: ["UsuarioSucursalEnroll"],
            label: "Usuario Sucursal Enroll",
            route: "/modulos/mantenimientos/usuario-sucursal-enroll",
          },
          {
            type: "link",
            permisos: ["Departamento_Usuario_PresupuestoEnroll"],
            label: "Departamento Usuario Enroll",
            route:
              "/modulos/mantenimientos/departamento-usuario-presupuesto-enrroll",
          },
          {
            type: "dropdown",
            label: "Autorización Captura",
            icon: iconsFA["chart-bar"],
            permisos: ["Reportes"],
            children: [
              {
                type: "link",
                permisos: ["EstadoPresupuesto"],
                label: "Estado Presupuesto",
                route: "/modulos/mantenimientos/estado-presupuesto",
              },
              {
                type: "link",
                permisos: ["RolEstadoPresupuesto"],
                label: "Rol Estado Presupuesto",
                route: "/modulos/mantenimientos/rol-estado-presupuesto",
              },
              {
                type: "link",
                permisos: ["Departamento_Auth_PresupuestoEnroll"],
                label: "Usuario Autorización Enroll",
                route:
                  "/modulos/mantenimientos/departamento-usuario-autorizacion-presupuesto-enrroll",
              },


            ],
          },
          // {
          //   type: "link",
          //   permisos: ["Config-Departamento-Enrroll"],
          //   label: "Departamentos",
          //   route: "/modulos/configuraciones/departamento-enrroll",
          // },
          {
            type: "link",
            permisos: ["Config-layout"],
            label: "Configuraciones",
            route: () => this.layoutService.openConfigpanel(),
          },
        ],
        label: "Configuraciones",
        icon: iconsFA["cog"],
      },
      {
        type: "dropdown",
        permisos: ["Aplicaciones"],
        children: [
          {
            type: "link",
            permisos: ["Aplicaciones_Mensajeria"],
            label: "Mensajeria",
            route: "/modulos/aplicaciones/mensajeria",
          },
        ],
        label: "Aplicaciones",
        icon: iconsIC["apps"],
      },
      //LH
      {
        type: "dropdown",
        permisos: ["ReportingServices"],
        children: [
          {
            type: "link",
            permisos: ["ReportingServices_ResumenCapital"],
            label: "Resumen de Capital",
            route: "/modulos/reportes/presupuesto/resumen/resumen-capital",
          },
          {
            type: "link",
            permisos: ["ReportingServices_EstadoResultado"],
            label: "Estado de Resultado",
            route: "/modulos/reportes/presupuesto/resumen/estado-resultados",
          },
          {
            type: "link",
            permisos: ["ReportingServices_ResumenEstadoResultado"],
            label: "Resumen Estado de Resultado",
            route: "/modulos/reportes/presupuesto/resumen/rs-resumen-estado-resultados",
          },
        ],
        label: "Reportes",
        icon: iconsIC["apps"],
      },
    ];
  }
  ngOnInit(): void {
    // this.toastr.success('Hello world!', 'Toastr fun!');
    if (this.authService.loggedIn()) {
      this.chatSignalR.usuarioid = this.authService.tokenDecoded.nameid;
      this.chatSignalR.startConnection();

      this.chatSignalR.mensajeRecibido.subscribe((x: ChatMessage) => {
        this.chatSignalR.playAudio();
        this._snackBar.open("Mensaje: " + x.message, null, {
          duration: 2000,
        });
      });
    }
  }
}
